import InsightViewer, { useImage } from '@lunit/insight-viewer';
import React from 'react';
import { useSelector } from 'react-redux';

const DcmViewer = ({ emailData }) => {
  const fileView = useSelector((state) => state.ediscoveryReducer.fileView);
  const MOCK_IMAGE = `wadouri:${fileView}`
  const { image } = useImage({ wadouri: MOCK_IMAGE })
  
  return (
    <div className="email-container scrollable-container">
      <InsightViewer image={image} />
    </div>
  );
};

export default DcmViewer;

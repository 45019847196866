import {
  Card,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BarChart from 'src/pages/system/charts/BarChart';
import { dispatch } from 'src/redux/store';
import chartColors from 'src/utils/chartColors';
import { GetDetails } from 'src/utils/fetchServices';
import { getStubCharts } from '../jobManagementActions';
import StubCharts from './StubCharts';
import { getCloudProfile, getFileStoreInfo } from '../../volume-management/volumeActions';
import { getCompanyInfo } from '../../company-list/companyActions';

const formatDate = (date) => moment(date).format('YYYY-MM-DD');
const getEpochtime = (date) => new Date(date).getTime();

const PolicyAndSchedule = ({
  policies,
  timeStamp,
  exclusion,
  intervalData,
  jobInfo,
  jobDetails,
  fileTypes,
  fileTypeState,
  register,
  watch,
  errors,
  setValue,
  isLocal,
  submit,
  getValues,
  schedules,
  disableButton,
  fileStoreName,
  fileStoreId,
  activeStatus,
  policy,
  params,
  stagingJob,
  proxyCheck,
  proxyPaths,
  proxy,
  detectServer,
  cloudProfileDetails,
  storageClasses,
  showClassStorage,
  storageClassesAzure,
  retentionShowData,
  legalHoldData,
  retentionBody,
  extractContentCheckState,
  companyStates,
  filteredStoreOptions
}) => {
  const defaultDetails = useSelector((state) => state.jobManagementReducer.defaultDetails);
  const [fileType, setFileType] = fileTypeState;
  const [exclusionCheck, setExclusionCheck] = useState(watch('applyExclusionPolicy') || false);
  const [active, setActive] = activeStatus;
  const [schedule, setSchedule] = schedules;
  const [policyid, setPolicyid] = policy;
  const [startDate, setStartDate] = useState(formatDate(new Date('1995/01/01')));
  const [endDate, setEndDate] = useState(formatDate(new Date()));
  const [repoAccessedDateJob, setRepoAccessedDateJob] = useState(formatDate(new Date()));
  const [intervalValue, setIntervalValue] = useState(watch('executionIntervalUnit') || 'Once');
  const [disableUpdate, setDisableUpdate] = disableButton;
  const [companyFilestoresId, setCompanyFilestoresId] = fileStoreId;
  const [companyFilestoresName, setCompanyFilestoresName] = fileStoreName;
  const [barChartYearPathData, setBarChartYearPathData] = useState([]);
  const [barChartPerYearDataPath, setBarChartPerYearDataPath] = useState([]);
  const [barChartPerYearLabelsPath, setBarChartPerYearLabelsPath] = useState([]);
  const [open, setOpen] = useState(false);
  const [storageClassOpen, setStorageClassOpen] = useState(false);
  const [proxyIds, setProxyIds] = proxy;
  const [firstProxyCheck, setFirstProxyCheck] = proxyCheck;
  const [countTime, setCountTime] = useState(false)
  const cloudProfileData = useSelector((state) => state.volumeReducer.cloudProfile);
  const [cloudProfileId, setCloudProfileId] = useState('')
  const [showRetention, setShowRetention] = retentionShowData
  const [showLegalHolds, setShowLegalHolds] = legalHoldData
  const [retentionData, setRetentionData] = retentionBody
  const [extractContentCheck, setExtractContentCheck] = extractContentCheckState

  const [storageClassOptions, setStorageClassOptions] = useState([
    { label: "S3 Standard", value: "STANDARD" },
    { label: "S3 Standard-Infrequent Access", value: "STANDARD_IA" },
    { label: "S3 One Zone-Infrequent Access", value: "ONEZONE_IA" },
    { label: "S3 Glacier Instant Retrieval", value: "GLACIER_IR" },
    { label: "S3 Glacier Flexible Retrieval (Formerly S3 Glacier)", value: "GLACIER" },
    { label: "S3 Glacier Deep Archive", value: "DEEP_ARCHIVE" },
  ])
  const [azureStorageClassOptions, setAzureStorageClassOptions] = useState([
    { label: "Hot", value: "HOT" },
    { label: "Cool", value: "COOL" },
    { label: "Cold", value: "COLD" },
    { label: "Archive", value: "ARCHIVE" },

  ])
  const [selectedStorage, setSelectedStorage] = storageClasses
  const [selectedStorageAzure, setSelectedStorageAzure] = storageClassesAzure
  const [showStorage, setShowStorage] = showClassStorage
  const [areaChartData, setAreaChartData] = useState([]);
  const [areaChartLabels, setAreaChartLabels] = useState([]);

  const stubCharts = useSelector((state) => state.jobManagementReducer.stubCharts);
  const areaCharts = useSelector((state) => state.jobManagementReducer.areaCharts);

  const handleShowStubCharts = () => {
    dispatch(getStubCharts(getEpochtime(startDate), getEpochtime(endDate), 'area'));
    dispatch(getStubCharts(getEpochtime(startDate), getEpochtime(endDate), 'bar'));
  };

  const userRole = localStorage.getItem('userRole')

useEffect(() => {
  if(filteredStoreOptions && filteredStoreOptions?.length === 1){
    if(filteredStoreOptions[0] === 1){
      setFileType(0)
    }else{
      setFileType(filteredStoreOptions[0])
    }
    setCompanyFilestoresId(null);
    setShowStorage(false);
    setValue('stagingPath', '');
  }
}, [filteredStoreOptions, setCompanyFilestoresId, setFileType, setShowStorage, setValue])

  useEffect(() => {
    if (stubCharts) {
      setBarChartYearPathData([]);
      setBarChartPerYearDataPath([]);
      setBarChartPerYearLabelsPath([]);

      let _barChartYearPathData = [];
      let _barChartPerYearDataPath = [];
      let _barChartPerYearLabelsPath = [];

      let pathKey = [];
      let labelBucket = [];
      let singleLabelBucket = [];
      let yearLabelByPath = [];
      let totalPathYearLabels = [];

      if (stubCharts['aggregations'] != undefined) {
        for (let bucket of stubCharts['aggregations'][2].buckets) {
          for (let item of bucket[3].buckets) {
            for (let place of item[4].buckets) {
              if (pathKey.indexOf(place.key) == -1) {
                pathKey.push(place.key);
              }
            }
          }
        }
        for (let path of pathKey) {
          for (let bucket of stubCharts['aggregations'][2].buckets) {
            let year = new Date(bucket.key).getFullYear().toString();
            if (
              parseInt(year) >= new Date(startDate).getFullYear() &&
              parseInt(year) <= new Date(endDate).getFullYear()
            ) {
              for (let item of bucket[3].buckets) {
                for (let place of item[4].buckets) {
                  if (path == place.key) {
                    if (singleLabelBucket.indexOf(item.key) == -1) {
                      singleLabelBucket.push(item.key);
                    }
                  }
                }
              }
            }
          }
          labelBucket.push(singleLabelBucket);
          singleLabelBucket = [];
        }
        for (let path of pathKey) {
          for (let singleKey of labelBucket[pathKey.indexOf(path)]) {
            let data = [];
            for (let bucket of stubCharts['aggregations'][2].buckets) {
              let year = new Date(bucket.key).getFullYear().toString();
              if (
                parseInt(year) >= new Date(startDate).getFullYear() &&
                parseInt(year) <= new Date(endDate).getFullYear()
              ) {
                for (let item of bucket[3].buckets) {
                  let bucketName = item.key;
                  if (bucketName == singleKey) {
                    for (let place of item[4].buckets) {
                      if (path == place.key) {
                        if (yearLabelByPath.indexOf(year) == -1) {
                          yearLabelByPath.push(year);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          yearLabelByPath.sort();
          totalPathYearLabels.push(yearLabelByPath);
          yearLabelByPath = [];
        }
        let i = 0;
        for (let path of pathKey) {
          for (let singleKey of labelBucket[pathKey.indexOf(path)]) {
            let data = [];
            let bucketName = '';
            let singleLabelYear = [];
            for (let bucket of stubCharts['aggregations'][2].buckets) {
              let year = new Date(bucket.key).getFullYear().toString();
              if (
                parseInt(year) >= new Date(startDate).getFullYear() &&
                parseInt(year) <= new Date(endDate).getFullYear()
              ) {
                for (let item of bucket[3].buckets) {
                  bucketName = item.key;
                  if (bucketName == singleKey) {
                    for (let place of item[4].buckets) {
                      if (path == place.key) {
                        if (_barChartPerYearLabelsPath.indexOf(year) == -1) {
                          _barChartPerYearLabelsPath.push(year);
                        }
                        singleLabelYear = totalPathYearLabels[pathKey.indexOf(path)];
                        data[singleLabelYear.indexOf(year)] = place.score;
                      }
                    }
                  }
                }
              }
            }
            _barChartPerYearDataPath.push({
              data: data,
              label: singleKey,
              backgroundColor: chartColors[i++],
            });
          }
          _barChartPerYearLabelsPath.sort();
          _barChartYearPathData.push({
            data: _barChartPerYearDataPath,
            label: _barChartPerYearLabelsPath,
            pathKey: path,
          });
          _barChartPerYearDataPath = [];
          _barChartPerYearLabelsPath = [];
        }

        setBarChartYearPathData(_barChartYearPathData);
        setBarChartPerYearDataPath(_barChartPerYearDataPath);
        setBarChartPerYearLabelsPath(_barChartPerYearLabelsPath);
      }
    }
  }, [stubCharts]);

  useEffect(() => {
    if (areaCharts && areaCharts['aggregations'] != undefined) {
      let _areaChartData = [];
      let _areaChartLabels = [];
      setAreaChartData([]);
      setAreaChartLabels([]);

      let dataArray = [];
      for (let bucket of areaCharts['aggregations'][2].buckets) {
        let year = new Date(bucket.key).getFullYear().toString();
        if (_areaChartLabels.indexOf(year) == -1) _areaChartLabels.push(year);
        dataArray.push(bucket[1].value);
      }
      _areaChartData.push({
        data: dataArray,
        label: 'Total count of files',
      });

      setAreaChartData(_areaChartData);
      setAreaChartLabels(_areaChartLabels);
    }
  }, [areaCharts]);

  const handleDeleteStubs = () => {
    setValue('repoAccessedDateJob', getEpochtime(repoAccessedDateJob));
    setValue('jobName', watch('name'));
    submit();
  };

  const DialogPrompt = () => {
    return (
      <>
        {((
          // watch('actionType') == 'ARCHIVE' ||
          // watch('actionType') == 'WITHOUTSTUB' ||
          watch('actionType') == 'STUB'
        ) &&
          watch('agentId')) &&
          <>
            <Dialog open={open} onClose={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 2,
              }}
            >
              <DialogContentText sx={{ p: 3, mb: 4 }}>
                {fileType !== 1 ?
                  " We notice the archive target server does not have the StubAccess agent installed - Please use MS shortcuts with Proxy Server(s) to accomplish seamless access for users."
                  :
                  "We notice the archive target server does not have the StubAccess agent installed - Please use MS shortcuts with OR without Proxy Server(s) to accomplish seamless access for users"}
              </DialogContentText>
              <Button variant='contained' sx={{ position: "absolute", right: 2, bottom: 2, mb: 1, mr: 2 }} onClick={handleClose}>OK</Button>
            </Dialog>

            {fileType == 4 ?
              <Typography variant="caption">
                The CIFS file share must be accessible to the remote agent, The agent must be installed on Windows and the agent service should use an account that grants access to the Target CIFS share.
              </Typography>
              : null}
          </>}
      </>
    )
  }

  useEffect(() => {
    // setValue('extractContent', false);
    // setExtractContentCheck(false)

    if (fileType == 4) {
      if (watch('actionType') == 'INGESTION') setValue('companyFilestoresId', 0);
      if (
        watch('actionType') == 'ARCHIVE' ||
        watch('actionType') == 'WITHOUTSTUB' ||
        watch('actionType') == 'ACTIVEARCHIVE'
      ) {
        setValue('processOfflineFiles', true);
        setValue('companyFilestoresId', 0);
      }
      if (watch('actionType') == 'STUB') {
        setValue('processOfflineFiles', false);
      }
    }
    // if(!detectServer){
    //   setOpen(true)
    // }
  }, [fileType]);

  useEffect(() => {
    if (!detectServer) {
      setOpen(true)
    }
  }, [detectServer])
const policyId = watch('policyId') ;
const stagingJobValue = watch('stagingJob') ;
const name = watch('name') ;
  useEffect(() => {
    if (stagingJobValue || stagingJobValue === 0) {
      setFileType(stagingJobValue);
    }
    if (!policyId) {
      setDisableUpdate(true);
    } else if (!name) {
      setDisableUpdate(true);
    } else {
      setDisableUpdate(false);
    }
    setPolicyid(policyId)
  }, [setDisableUpdate, setFileType, setPolicyid, policyId, stagingJobValue, name]);

  useEffect(() => {
    if (fileTypes && fileTypes.length === 1) {
      setCompanyFilestoresId(fileTypes[0].id)
      setValue('companyFilestoresId', fileTypes[0].id)
    }
  }, [fileTypes, setCompanyFilestoresId, setValue]); 

  useEffect(() => {
    // if(companyFilestoresId == null){
    //   setShowStorage(false)
    // }
    fileTypes &&
      fileTypes?.length &&
      fileTypes.map((item, i) => {
        if (item.id == companyFilestoresId) {
          setCompanyFilestoresName(item.fileStoreType);
          setValue('stagingPath', item.fileStorePath)
          if (fileType == 2 || fileType == 3) {
            setCloudProfileId(item.cloudProfileId)
          }
        }
      });
  }, [companyFilestoresId]);


  useEffect(() => {
    if (stagingJob == 1) {
      fileTypes &&
        fileTypes?.length &&
        fileTypes.map((item, i) => {
          if (item.fileStorePath == watch('stagingPath')) {
            setCompanyFilestoresId(item.id)
          }
        });
    }
  }, [stagingJob])

  useEffect(() => {
    if (cloudProfileId) {
      cloudProfileData?.length && cloudProfileData.map((item, i) => {
        if (item.id === cloudProfileId) {
          if (item.awsAccessUrl == 's3.amazonaws.com') {
            setShowStorage(true)
          }
          else {
            setShowStorage(false)
          }
        }
      })
    }
  }, [cloudProfileId, companyFilestoresId])

  //  useEffect(()=>{
  //   {isLocal && watch('agentId') != 1 && (
  //     // setOpen(true)
  //   )}
  //  },[])

  const handleClose = () => {
    setOpen(false);
  };

  const checkProxyPath = (p) => {
    let val = false;
    proxyIds.map((id) => {
      if (id == p.id) {
        val = true;
        return val;
      }
    });
    return val;
  };

  const sharePathClick = (e, p) => {
    let val = e.target.checked;
    if (val) {
      setProxyIds([...proxyIds, p.id]);
    } else {
      let ids = proxyIds.filter((id) => id != p.id);
      setProxyIds([...ids]);
    }
  };

  const timeStop = () => {
    setCountTime(false)
  }

  useEffect(() => {
    if (proxyIds.length) {
      setFirstProxyCheck(true)
      // setValue('createUrlFiles', true)
      if (fileType !== 1) {
        setCountTime(true)
        setTimeout(() => {
          timeStop()
        }, 24000);
      }
    } else {
      setCountTime(false)
    }
  }, [proxyIds])

  useEffect(() => {
    if (selectedStorage === 'DEEP_ARCHIVE' || selectedStorage === 'GLACIER') {
      setStorageClassOpen(true)
    } else {
      setStorageClassOpen(false)
    }
  }, [selectedStorage, showStorage])

  useEffect(() => {
    if (showRetention == false) {
      setRetentionData({ retentionUntilDate: null, retentionMode: null })
    }
  }, [showRetention])
  let check = true
  return (
    <>
      {watch('actionType') !== 'DELETESTUB' && (
        <Card sx={{ m: '20px 80px 40px 80px', p: '20px' }}>
          <CardHeader title="Policy" sx={{ mb: '5px' }} />
          <Grid container spacing={3} sx={{ mx: '10px' }}>
            <Grid item xs={10} md={6}>
              <FormControl fullWidth>
                {/* <InputLabel id="type">Policy</InputLabel> */}
                <Select
                  labelId="policy"
                  name="policyId"
                  variant="standard"
                  label="Policy"
                  value={watch('policyId') || ''}
                  {...register('policyId')}
                >
                  {policies.length &&
                    policies.map((policy) => {
                      return (
                        <MenuItem key={policy.id} value={policy.id}>
                          {policy.name}
                        </MenuItem>
                      );
                    })}
                </Select>
                {/* {watch('fileSystemType') == 'remote' && (
                )} */}
              </FormControl>
            </Grid>
            <Grid item xs={10} md={5}>
              {exclusionCheck && watch('fileSystemType') == 'remote' && (
                <FormControl fullWidth>
                  <InputLabel id="agent">Exclusion Policy</InputLabel>
                  <Select
                    labelId="exclusion"
                    name="exclusionPolicyId"
                    variant="standard"
                    label="Exclusion Policy"
                    value={watch('exclusionPolicyId') || ''}
                    {...register('exclusionPolicyId')}
                  >
                    {exclusion &&
                      exclusion.map((exc) => (
                        <MenuItem key={exc.id} value={exc.id}>
                          {exc.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <FormControlLabel
                  name="applyExclusionPolicy"
                  control={<Checkbox />}
                  checked={exclusionCheck}
                  onChange={(e) => {
                    setValue('applyExclusionPolicy', e.target.checked);
                    setExclusionCheck(e.target.checked);
                  }}
                  label="Apply Exclusion Rule"
                />
                {watch('actionType') === 'INDEXING' && (
                  <FormControlLabel control={<Checkbox />} label="Extract Content" {...register('extractContent')} />
                )}
                {/* <FormControlLabel
                  name="checkDeDuplication"
                  control={<Checkbox />}
                  label="Windows Deduplication"
                  {...register('checkDeDuplication')}
                /> */}
              </FormGroup>
            </Grid>
            {(watch('actionType') == 'STUB' ||
              watch('actionType') == 'ARCHIVE' ||
              watch('actionType') == 'WITHOUTSTUB' ||
              watch('actionType') == 'ACTIVEARCHIVE') &&
            (fileType == 2 || fileType == 3 || 4 || fileType == 0) ? (
              <Grid item xs={12} md={2}>
                <FormControlLabel
                  name="extractContent"
                  checked={extractContentCheck}
                  control={<Checkbox />}
                  // label={fileType == 4 ? "Index File Content" : "Extract Content"}
                  label={fileType == 4 ? 'Index File Content' : 'Index File Content'}
                  onChange={(e) => {
                    setValue('extractContent', e.target.checked);
                    setExtractContentCheck(e.target.checked);
                  }}
                  disabled={userRole === 'superadmin' && companyStates?.companyInfo.indexContent == 0}
                />
              </Grid>
            ) : (
              <Grid item xs={12} md={4}></Grid>
            )}

            <Grid item xs={12} md={4}>
              {watch('extractContent') && (
                <TextField
                  name="tags"
                  label="Machine Learning Categorization Tag"
                  variant="standard"
                  helperText={errors.tags && errors.tags.message}
                  error={errors.tags && errors.tags.message}
                  {...register('tags')}
                  sx={{ width: '100%' }}
                />
              )}
            </Grid>
          </Grid>
        </Card>
      )}

      {watch('actionType') == 'STUB' ||
      watch('actionType') == 'WITHOUTSTUB' ||
      watch('actionType') == 'ARCHIVE' ||
      watch('actionType') == 'INGESTION' ||
      watch('actionType') == 'ACTIVEARCHIVE' ? (
        <Card sx={{ m: '0 80px 40px 80px', p: '20px' }}>
          <CardHeader title="Target Destination" sx={{ mb: '5px' }} />
          <Grid container spacing={4} sx={{ mx: '10px' }}>
            <Grid item xs={10} md={6}>
              <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <FormLabel id="type">File Store Type</FormLabel>
                <Select
                  name="fileStoreType"
                  labelId="type"
                  variant="standard"
                  // label="File Store"
                  value={fileType}
                  onChange={(e) => {
                    setCompanyFilestoresId(null);
                    setShowStorage(false);
                    setValue('stagingPath', '');
                    setFileType(e.target.value);
                  }}
                  sx={{ minWidth: '250px', ml: 4, mb: 2 }}
                >
                      {filteredStoreOptions.includes(1) && <MenuItem value={0}>Repository</MenuItem>}
                      {filteredStoreOptions.includes(2) && <MenuItem value={2}>S3 Cloud</MenuItem>}
                      {isLocal && filteredStoreOptions.includes(4) && <MenuItem value={4}>Windows SMB Target</MenuItem>}
                      {filteredStoreOptions.includes(3) && <MenuItem value={3}>Azure Cloud Blob</MenuItem>}
                      {filteredStoreOptions.length === 0 && <MenuItem disabled>No option found</MenuItem>}
                </Select>
              </FormControl>
              {DialogPrompt()}
            </Grid>

            <Grid item xs={10} md={4}>
              {(fileType == 0 || fileType == 2 || fileType == 3 || fileType == 4) && (
                <FormControl fullWidth>
                  <InputLabel id="agent">File Store</InputLabel>
                  <Select
                    name="companyFilestoresId"
                    labelId="exclusion"
                    variant="standard"
                    label="File Store"
                    value={companyFilestoresId || ''}
                    {...register('companyFilestoresId')}
                    onChange={(e) => {
                      setCompanyFilestoresId(e.target.value);
                    }}
                  >
                    {fileTypes &&
                      fileTypes?.length &&
                      fileTypes.map((type) => {
                        return (
                          <MenuItem key={type.id} value={type.id}>
                            {type.fileStoreType}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              )}

              {fileType == 4 && (
                <>
                  {/* {params && params.id ? null : */}
                  <TextField
                    name="stagingPath"
                    label="Path"
                    variant="standard"
                    {...register('stagingPath')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                    sx={{ width: '100%', mt: 2 }}
                    helperText={errors.stagingPath && errors.stagingPath.message}
                    error={errors.stagingPath && errors.stagingPath.message}
                  />
                  {/* } */}
                  {/* {proxyIds?.length ?
                    <FormControlLabel
                      name="createUrlFiles"
                      control={<Checkbox checked={true} />}
                      label="Create Microsoft Shortcuts"
                    // {...register('createUrlFiles')}
                    />
                    :
                    <FormControlLabel
                      name="createUrlFiles"
                      control={<Checkbox checked={watch('createUrlFiles')} />}
                      label="Create Microsoft Shortcuts"
                      {...register('createUrlFiles')}
                    />
                  } */}
                </>
              )}
              {(fileType == 0 || fileType == 2 || fileType == 3) && !companyFilestoresId && (
                <Typography color="red" variant="body2">
                  * File Store Path is Mandatory
                </Typography>
              )}
              {fileType == 1 && watch('stagingPath') == '' && (
                <Typography variant="body2">Target Network Path required e.g. \\Server\Archive</Typography>
              )}
            </Grid>

            {fileType === 2 && showStorage ? (
              <>
                <Grid item xs={10} md={6} sx={{ paddingTop: '10px !important' }} fullWidth>
                  <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <FormLabel id="storage">Storage Class</FormLabel>
                    <Select
                      name="storageClass"
                      labelId="storage"
                      variant="standard"
                      // label="File Store"
                      value={selectedStorage}
                      onChange={(e) => {
                        setSelectedStorage(e.target.value);
                      }}
                      sx={{ minWidth: '250px', ml: 4, mb: 2 }}
                    >
                      {storageClassOptions?.length &&
                        storageClassOptions.map((item, i) => <MenuItem value={item.value}>{item.label}</MenuItem>)}
                    </Select>
                  </FormControl>
                  {storageClassOpen && (
                    <Typography variant="caption" sx={{ display: 'block', mb: 1 }}>
                      Files on the selected storage class are not instantly accessible, therefore we recommend you do
                      not stub this data, and only select the archive option.
                    </Typography>
                  )}
                  <Typography variant="caption">
                    A list of storage classes for Amazon S3, the cost of the storage get cheaper per TB/month, as you go
                    down the list for more details click here,{' '}
                    <span>
                      <a href="https://aws.amazon.com/s3/pricing/" target="_blank">
                        {' '}
                        https://aws.amazon.com/s3/pricing/
                      </a>
                    </span>
                  </Typography>
                </Grid>
              </>
            ) : null}
            {(fileType === 2 || fileType == 3) && defaultDetails.enableS3ObjectLock ? (
              <>
                <Grid
                  item
                  xs={10}
                  md={6}
                  gap={2}
                  flexDirection="column"
                  sx={{ paddingTop: '16px !important', display: 'flex' }}
                >
                  <Grid container alignItems="flex-start" xs={12} md={12}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <FormControlLabel
                        name="retention"
                        // {...register('retention')}
                        control={<Checkbox />}
                        checked={showRetention}
                        label="Retention"
                        onChange={(e) => setShowRetention(e.target.checked)}
                      />
                    </FormGroup>
                    {showRetention && showRetention == true ? (
                      <>
                        <Stack sx={{ width: '30%', ml: 4 }}>
                          <Typography sx={{ fontSize: '12px', color: '#919EAB', transform: 'translateY(-9px)' }}>
                            Retention Date
                          </Typography>
                          <TextField
                            name="retentionUntilDate"
                            variant="standard"
                            type="date"
                            sx={{ width: '100%' }}
                            value={formatDate(retentionData.retentionUntilDate)}
                            onChange={(e) => setRetentionData({ ...retentionData, retentionUntilDate: e.target.value })}
                          />
                          {showRetention && showRetention == true ? (
                            <Typography sx={{ mt: 2, fontSize: '12px', color: '#919EAB', fontStyle: 'italic' }}>
                              Retention Date & Retention Mode is required.
                            </Typography>
                          ) : null}
                        </Stack>
                        {fileType !== 3 ? (
                          <Stack sx={{ ml: 4 }}>
                            <Typography sx={{ fontSize: '12px', color: '#919EAB', transform: 'translateY(-9px)' }}>
                              Retention Mode
                            </Typography>
                            <RadioGroup
                              name="retentionMode"
                              value={retentionData.retentionMode}
                              onChange={(e) => setRetentionData({ ...retentionData, retentionMode: e.target.value })}
                            >
                              <FormControlLabel value={'GOVERNANCE'} control={<Radio />} label="Governance " />
                              <FormControlLabel value={'COMPLIANCE'} control={<Radio />} label="Compliance" />
                            </RadioGroup>
                          </Stack>
                        ) : null}
                      </>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <FormControlLabel
                        name="legalHolds"
                        // {...register('processOfflineFiles')}
                        control={<Checkbox />}
                        checked={showLegalHolds ? true : false}
                        label="Legal Holds"
                        onChange={(e) => setShowLegalHolds(e.target.checked)}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </>
            ) : null}
            {fileType === 3 ? (
              <>
                <Grid item xs={10} md={6} sx={{ paddingTop: '10px !important' }} fullWidth>
                  <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <FormLabel id="storage">Storage Class</FormLabel>
                    <Select
                      name="storageClass"
                      labelId="storage"
                      variant="standard"
                      // label="File Store"
                      value={selectedStorageAzure}
                      onChange={(e) => {
                        setSelectedStorageAzure(e.target.value);
                      }}
                      sx={{ minWidth: '250px', ml: 4, mb: 2 }}
                    >
                      {azureStorageClassOptions?.length &&
                        azureStorageClassOptions.map((item, i) => <MenuItem value={item.value}>{item.label}</MenuItem>)}
                    </Select>
                  </FormControl>
                  {/* { storageClassOpen && 
            <Typography variant="caption" sx={{display:'block',mb:1}}>
              Files on the selected storage class are not instantly accessible, therefore we recommend you do not stub this data, and only select the archive option. 
            </Typography>
              } */}
                  {/* <Typography variant="caption">
            A list of storage classes for Amazon S3, the cost of the storage get cheaper per TB/month, as you go down the list for more details click here, <span><a href='https://aws.amazon.com/s3/pricing/' target="_blank" > https://aws.amazon.com/s3/pricing/</a></span> 
            </Typography> */}
                </Grid>
              </>
            ) : null}
            {!detectServer && (
              <Grid item xs={12} md={12}>
                {watch('showEnableProxyServer') && (
                  <Grid item xs={12} md={11}>
                    <Card sx={{ pb: '20px' }}>
                      {fileType !== 1 && !countTime ? (
                        <FormControlLabel
                          name="createUrlFiles"
                          control={<Checkbox checked={watch('createUrlFiles') ? true : false} />}
                          label="Create Microsoft Shortcuts"
                          sx={{ pl: 3, fontSize: '20px' }}
                          {...register('createUrlFiles')}
                          // onChange={()=>setFirstProxyCheck(!firstProxyCheck)}
                        />
                      ) : fileType !== 1 && countTime ? (
                        <FormControlLabel
                          name="createUrlFiles"
                          control={<Checkbox checked={watch('createUrlFiles') ? true : false} />}
                          label="Create Microsoft Shortcuts"
                          sx={{ pl: 3, fontSize: '20px' }}
                          {...register('createUrlFiles')}
                          // onChange={() => setFirstProxyCheck(!firstProxyCheck)}
                        />
                      ) : null}
                      <CardHeader sx={{ m: 0, p: 0, pl: 3 }} title="Proxy Server Share Path" />
                      <Stack flexDirection="row">
                        {proxyPaths?.length
                          ? proxyPaths.map((p) => (
                              <FormControlLabel
                                sx={{ marginLeft: '15px' }}
                                control={<Checkbox checked={checkProxyPath(p)} />}
                                label={p.path}
                                onChange={(e) => sharePathClick(e, p)}
                              />  
                            ))
                          : null}
                      </Stack>
                      {watch('createUrlFiles') && fileType !== 4 && !proxyIds.length ? (
                        <Typography sx={{ pl: 3, fontSize: '13px', color: 'red' }}>
                          Please select at least 1 proxyServer.
                        </Typography>
                      ) : null}
                    </Card>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Card>
      ) : null}

      {watch('actionType') == 'DELETESTUB' && (
        <Card sx={{ m: '20px 80px 40px 80px', p: '20px' }}>
          <CardHeader title="Stubs Charts" sx={{ mb: '5px' }} />
          <Grid container spacing={3} sx={{ mx: '10px' }}>
            <Grid item xs={10} md={5}>
              <Typography sx={{ fontSize: '12px', color: '#919EAB', transform: 'translateY(-9px)' }}>
                Choose a start date
              </Typography>
              <TextField
                variant="standard"
                type="date"
                sx={{ width: '100%' }}
                defaultValue={startDate}
                onChange={(e) => setStartDate(formatDate(e.target.value))}
              />
            </Grid>
            <Grid item xs={10} md={5}>
              <Typography sx={{ fontSize: '12px', color: '#919EAB', transform: 'translateY(-9px)' }}>
                Choose a end date
              </Typography>
              <TextField
                variant="standard"
                type="date"
                sx={{ width: '100%' }}
                defaultValue={endDate}
                onChange={(e) => setEndDate(formatDate(e.target.value))}
              />
            </Grid>
            <Grid item xs={10} md={5}>
              <Button variant="contained" sx={{ color: '#ffff', ml: '10px' }} onClick={handleShowStubCharts}>
                Show Stub Charts
              </Button>
            </Grid>
            {barChartYearPathData.length
              ? barChartYearPathData.map((barChart, i) => (
                  <Grid key={i} item xs={10} md={10}>
                    <StubCharts data={barChart.data} labels={barChart.label} pathKey={barChart.pathKey} />
                  </Grid>
                ))
              : null}
          </Grid>
        </Card>
      )}

      {watch('actionType') == 'DELETESTUB' && (
        <Card sx={{ m: '20px 80px 40px 80px', p: '20px' }}>
          <CardHeader title="Delete Stubs Job" sx={{ mb: '5px' }} />
          <Grid container spacing={3} sx={{ mx: '10px' }}>
            <Grid item xs={10} md={5}>
              <Typography sx={{ fontSize: '12px', color: '#919EAB', transform: 'translateY(-9px)' }}>
                Choose Repo Accessed date
              </Typography>
              <TextField
                variant="standard"
                type="date"
                sx={{ width: '100%' }}
                defaultValue={repoAccessedDateJob}
                onChange={(e) => setRepoAccessedDateJob(formatDate(e.target.value))}
              />
            </Grid>
            <Grid item xs={10} md={5}>
              <TextField
                name="uncPath"
                label="UNC Path (e.g \\server\folder)"
                variant="standard"
                helperText={errors.uncPath && errors.uncPath.message}
                error={errors.uncPath && errors.uncPath.message}
                {...register('uncPath')}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={10} md={5}>
              <Button variant="contained" sx={{ color: '#ffff', ml: '10px' }} onClick={handleDeleteStubs}>
                Delete Stub Job
              </Button>
              <Link to="/dashboard/job-list" style={{ textDecoration: 'none' }}>
                <Button variant="contained" sx={{ color: '#ffff', m: '10px' }}>
                  Cancel
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Card>
      )}
      {watch('actionType') != 'DELETESTUB' && (
        <Card sx={{ m: '0 80px 40px 80px', p: '20px' }}>
          <Grid container spacing={3} sx={{ mx: '10px' }}>
            <Grid item xs={10} md={7}>
              <FormControl
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <FormLabel id="sourceId">Source Data</FormLabel>
                <RadioGroup
                  aria-labelledby="sourceId"
                  name="activeJob"
                  sx={{ display: 'flex', flexDirection: 'row' }}
                  defaultValue={active}
                  {...register('activeJob')}
                  onChange={(e) => {
                    setValue('activeJob', e.target.value === 'true');
                    setActive(e.target.value === 'true');
                  }}
                >
                  <FormControlLabel value={true} control={<Radio />} label="Active Queue" />
                  <FormControlLabel value={false} control={<Radio />} label="Scan Mode" />
                </RadioGroup>
                {
                  !watch('activeJob') && <FormControlLabel
                    name="scanAnalyzedData  "
                    control={<Checkbox checked={watch('scanAnalyzedData') ? true : false} />}
                    label="Analyzed Data"
                    sx={{ pl: 3, fontSize: '20px' }}
                    {...register('scanAnalyzedData')}
                  // onChange={() => setFirstProxyCheck(!firstProxyCheck)}
                  />
                }
              </FormControl>
            </Grid>
            <Grid item xs={10} md={7}>
              <FormControl
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <FormLabel id="schedule">Schedule</FormLabel>
                <RadioGroup
                  aria-labelledby="schedule"
                  name="scheduled"
                  {...register('scheduled')}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                  defaultValue={schedule}
                  onChange={(e) => {
                    setValue('scheduled', e.target.value == 'true');
                    setSchedule(e.target.value === 'true');
                  }}
                >
                  <FormControlLabel value={false} control={<Radio />} label="Run now" />
                  <FormControlLabel value={true} control={<Radio />} label="Schedule" />
                </RadioGroup>
              </FormControl>
            </Grid>
            {schedule ? (
              <>
                <Grid item xs={10} md={5}>
                  {timeStamp.timeStamp && (
                    <Typography name="timeStamp" variant="h6">
                      Current System Date/Time : {timeStamp.timeStamp}{' '}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={10} md={5}>
                  <Typography sx={{ fontSize: '12px', color: '#919EAB', transform: 'translateY(-9px)' }}>
                    Execution Time
                  </Typography>
                  <TextField
                    name="execTimeStartDate"
                    // label="Execution Time"
                    variant="standard"
                    type="datetime-local"
                    {...register('execTimeStartDate')}
                    // value={
                    // jobDetails.execTimeStartDate ? moment(jobDetails.execTimeStartDate).format('YYYY-MM-DDTHH:mm') : ''
                    // }
                    sx={{ width: '100%' }}
                    // onChange={(e) =>
                    //   // setJobDetails({ ...jobDetails, [e.target.name]: moment(e.target.value).format('MM/DD/YYYY HH:mm') })
                    // }
                  />
                </Grid>
                <Grid item xs={10} md={5}>
                  <FormControl fullWidth>
                    <InputLabel id="interval">Select Interval</InputLabel>
                    <Select
                      name="executionIntervalUnit"
                      labelId="interval"
                      variant="standard"
                      label="Select Interval"
                      value={intervalValue}
                      {...register('executionIntervalUnit')}
                      onChange={(e) => setIntervalValue(e.target.value)}
                    >
                      {intervalData.length &&
                        intervalData.map((interval, i) => {
                          return (
                            <MenuItem key={i} value={interval.value}>
                              {interval.label}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={10} md={5}>
                  {intervalValue === 'Custom' && (
                    <TextField
                      name="executionInterval"
                      label="Process Interval (mins)"
                      variant="standard"
                      type="number"
                      {...register('executionInterval')}
                      sx={{ width: '100%' }}
                    />
                  )}
                </Grid>
              </>
            ) : null}
          </Grid>
        </Card>
      )}
    </>
  );
};

export default PolicyAndSchedule;

import React from 'react';

const EmailViewer = ({ emailData }) => {
  const { subject, body, senderName, recipients, attachments } = emailData;

  return (
    <div className="email-container scrollable-container">
      <header className="email-header">
        <h1 className="email-subject">{subject}</h1>
        <p className="email-from">
          <strong>From:</strong> {senderName}
        </p>
        <p className="email-to">
          <strong>To:</strong> {recipients.map(recipient => recipient.name)?.join(', ')}
        </p>
      </header>
      <main className="email-body-container">
        <div className="email-body">{body}</div>
      </main>
      {attachments?.length > 0 && (
        <footer className="email-footer">
          <h3 className="attachments-title">Attachments</h3>
          <ul className="attachments-list">
            {attachments.map((attachment, index) => (
              <li key={index} className="attachment-item">
                <a
                  href={`data:${attachment.contentType};base64,${attachment.fileData}`}
                  download={attachment.fileName}
                  className="attachment-link"
                >
                  📎 {attachment.fileName}
                </a>
              </li>
            ))}
          </ul>
        </footer>
      )}
    </div>
  );
};

export default EmailViewer;

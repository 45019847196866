import {
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import {  getAgents, getGlobalConfiguration, restoreFileData } from '../ediscoveryActions';

const RestoreModal = ({ openModal, close, fileProperties }) => {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const [overwriteFiles, setOverwriteFiles] = useState(true);
  const agents = useSelector((state) => state.ediscoveryReducer.agents);
  const globalConfig = useSelector((state) => state.ediscoveryReducer.globalConfig);

  const [restoreFile, setRestoreFile] = useState({});
  const [restoreDetails, setRestoreDetails] = useState({});

  useEffect(() => {
    if (fileProperties) {
      setRestoreFile({
        sourcePath: fileProperties.documentPath || '',
        destinationPath: fileProperties.documentPath || '',
        lastModifiedDate: moment(fileProperties.lastModificationDate).format('X') || '',
        uuid: fileProperties.plainUuid || '',
      });
    }
  }, [fileProperties]);
  
  useEffect(() => {
    setRestoreDetails({
      agentId: '',
      domain: globalConfig.restoreDomainName || '',
      userName: globalConfig.restoreUser || '',
      password: globalConfig.restorePassword || '',
    });
  }, [globalConfig]);

  useEffect(() => {
    dispatch(getAgents({}));
    dispatch(getGlobalConfiguration());
  }, []);

  const handleSubmit = () => {
    restoreDetails.restoreFile = [{ restoreFile }];
    restoreDetails.overwriteFiles = overwriteFiles;
    restoreDetails.companyId = +localStorage.getItem('companyId');
    dispatch(restoreFileData(restoreDetails));
    close()
  };
  const handleDomainChange = (event) => {
    setRestoreDetails((prevDetails) => ({
      ...prevDetails,
      domain: event.target.value,
    }));
  }

  return (
    <>
      <Modal
        open={openModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '100%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ p: '40px' }}>
              <Typography variant="h4">Restore File</Typography>
              <Stack gap={2}>
                <TextField name="path" variant="standard" value={restoreFile.sourcePath || ''} label="Path" disabled />
                <TextField
                  variant="standard"
                  value={restoreFile.destinationPath || ''}
                  label="Destination Path"
                  onChange={(e) => setRestoreFile({ ...restoreFile, destinationPath: e.target.value })}
                />
                <TextField name="domain" variant="standard" value={restoreDetails.domain || ''} label="Domain" onChange={handleDomainChange} disabled />
                <FormControl fullWidth>
                  <InputLabel id="agent">Agent</InputLabel>
                  <Select
                    name="agentId"
                    labelId="agent"
                    variant="standard"
                    label="Agent"
                    onChange={(e) => setRestoreDetails({ ...restoreDetails, agentId: e.target.value })}
                    required
                  >
                    {agents.content &&
                      agents.content.map((agent) => {
                        return (
                          <MenuItem key={agent.id} value={agent.id}>
                            {agent.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <TextField
                  variant="standard"
                  value={restoreDetails.userName || ''}
                  label="User name"
                  onChange={(e) => setRestoreDetails({ ...restoreDetails, userName: e.target.value })}
                  required
                />
                <TextField
                  variant="standard"
                  value={restoreDetails.password || ''}
                  label="Password"
                  type="password"
                  onChange={(e) => setRestoreDetails({ ...restoreDetails, password: e.target.value })}
                  required
                />
              </Stack>
              <FormControlLabel checked={overwriteFiles} control={<Checkbox />} label='Overwrite Files' onChange={() => setOverwriteFiles(!overwriteFiles)} />
              <Stack direction="row" gap={2} sx={{ my: '20px' }}>
                <Button variant="contained" onClick={close}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={restoreDetails.jobName === '' || restoreDetails.destinationPath === ''}
                  onClick={handleSubmit}
                >
                  Restore
                </Button>
              </Stack>
            </Card>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default RestoreModal;

import {
  Button,
  Card,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import Logo from 'src/components/Logo';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';

const JobModal = ({
  errors,
  register,
  openModal,
  close,
  getValues,
  policies,
  agents,
  fileTypes,
  submit,
  fileTypeState,
  selectedStorage,
  showStorage,
  proxyIds,
  selectedStorageAzure,
  showRetention,
  retentionBody,
}) => {
  const { themeStretch } = useSettings();
  const [policyName, setPolicyName] = useState('');
  const [agentName, setAgentName] = useState('');
  const [fileStore, setFileStore] = useState('');
  const [fileType, setFileType] = fileTypeState;
  const [isNameInput, setIsNameInput] = useState(false);
  const proxyPaths = useSelector((state) => state.globalReducer.proxyPaths);
  const [showProxy, setShowProxy] = useState([]);
  const [retentionData, setRetentionData] = retentionBody;

  const storageClassOptions = [
    { label: 'S3 Standard', value: 'STANDARD' },
    { label: 'S3 Standard-Infrequent Access', value: 'STANDARD_IA' },
    { label: 'S3 One Zone-Infrequent Access', value: 'ONEZONE_IA' },
    { label: 'S3 Glacier Instant Retrieval', value: 'GLACIER_IR' },
    { label: 'S3 Glacier Flexible Retrieval (Formerly S3 Glacier)', value: 'GLACIER' },
    { label: 'S3 Glacier Deep Archive', value: 'DEEP_ARCHIVE' },
  ];

  const azureStorageClassOptions = [
    { label: 'Hot', value: 'HOT' },
    { label: 'Cool', value: 'COOL' },
    { label: 'Cold', value: 'COLD' },
    { label: 'Archive', value: 'ARCHIVE' },
  ];

  const getInfo = () => {
    switch (+fileType) {
      case 0:
        return 'Repository (' + fileStore + ')';
      // case 1:
      //   return 'Staging Path (' + getValues('stagingPath') + ')';
      case 2:
        return 'S3 Cloud (' + fileStore + ')';
      case 3:
        return 'Azure Cloud Blob (' + fileStore + ')';
      case 4:
        return 'Windows SMB Target (' + fileStore + ')';
      default:
        return 'NA';
    }
  };

  const getStorage = (data) => {
    let setStorage = '';
    if (fileType == 2) {
      storageClassOptions.map((item, i) => {
        if (item.value == data) {
          setStorage = item.label;
        }
      });
    } else if (fileType == 3) {
      azureStorageClassOptions.map((item, i) => {
        if (item.value == data) {
          setStorage = item.label;
        }
      });
    }
    return setStorage;
  };

  const getIncludedPaths = (list) => {
    return list?.join(', ');
  };

  const retentionLabel = (data) => {
    let label = '';
    if (data == 'GOVERNANCE') {
      label = 'Governance';
      return label;
    } else if (data === 'COMPLIANCE') {
      label = 'Compliance';
      return label;
    }
  };

  useEffect(() => {
    if (policies && policies.length > 0) {
      let res = policies.filter((obj) => obj.id == getValues('policyId'));
      if (res && res.length > 0) {
        setPolicyName(res[0].name);
      }
    }
    if (agents && agents.content && agents.content.length > 0) {
      let res2 = agents.content.filter((obj) => obj.id == getValues('agentId'));
      if (res2 && res2.length > 0) {
        setAgentName(res2[0].name);
      }
    }
    if (fileTypes && fileTypes.length > 0) {
      let res3 = fileTypes.filter((obj) => obj.id == getValues('companyFilestoresId'));
      if (res3 && res3.length > 0) {
        setFileStore(res3[0].fileStoreType);
      }
    }
  }, [openModal]);

  useEffect(() => {
    let proxyPathData = [];
    if (proxyIds && proxyIds.length) {
      proxyIds.map((pid) => {
        proxyPaths?.length &&
          proxyPaths.map((ppath) => {
            if (pid == ppath.id) {
              proxyPathData.push(ppath.path);
            }
          });
      });
    }
    setShowProxy(proxyPathData);
  }, [proxyIds]);

  const getNthWeekdayOfMonth = (date, weekday, nth) => {
    // Clone the date to avoid mutating it
    const firstDayOfMonth = date.clone().startOf('month');

    // Find the first occurrence of the weekday in the month
    let firstOccurrence = firstDayOfMonth.clone().isoWeekday(weekday);

    // If the first occurrence is in the next month, adjust
    if (firstOccurrence.date() > 7) {
      firstOccurrence = firstOccurrence.add(1, 'weeks');
    }

    // Now calculate the nth occurrence
    const nthWeekday = firstOccurrence.add((nth - 1) * 7, 'days');
    return nthWeekday;
  }
  const getOrdinalSuffix = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const modulo = number % 100;
    return number + (suffixes[(modulo - 20) % 10] || suffixes[modulo] || suffixes[0]);
  }
  const getMonthlyValue = () => {
    const date = moment(getValues('execTimeStartDate'));
    // Get the week of the month
    const firstDayOfMonth = date.clone().startOf('month');
    const weekOfMonth = Math.ceil(date.diff(firstDayOfMonth, 'days', true) / 7);
    // Get the day of the week (e.g., Friday)
    const dayOfWeek = date.format('dddd'); // This will give us "Friday"
    // Let's get the nth occurrence of the weekday (e.g., 2nd Friday of the month)
    const nthFriday = getNthWeekdayOfMonth(date, 5, 2); // 5 = Friday (ISO week)
    // Use the ordinal suffix for weekOfMonth
    const ordinalWeek = getOrdinalSuffix(weekOfMonth);
    const formattedDate = `Monthly on the ${ordinalWeek} ${dayOfWeek}`;
    return formattedDate
  };
  return (
    <>
      <Modal
        open={openModal}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '50%' }}>
          <Card
            sx={{
              backgroundColor: '#ffff',
              p: '40px',
              maxHeight: '700px',
              overflowY: 'auto',
            }}
          >
            <Logo />
            <Stack alignItems="center" justifyContent="center">
              <CardHeader title="Job Summary" />
              <Grid container spacing={3} sx={{ m: '20px' }}>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Name:</Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  {isNameInput ? (
                    <Stack direction="row" gap={2} alignItems="center">
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="name"
                          // label="Name"
                          helperText={errors.name && errors.name.message}
                          error={errors.name && errors.name.message}
                          {...register('name')}
                          variant="standard"
                          sx={{ width: '100%' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // onChange={handleChange}
                          required
                        />
                      </Grid>
                      <IconButton size='small' onClick={() => setIsNameInput(false)} aria-label="fingerprint" color="primary">
                        <CheckIcon fontSize='14' />
                      </IconButton>
                    </Stack>
                  ) : (
                    <Stack direction='row' alignItems='center' gap={2}>
                      <Typography sx={{ fontSize: '0.9rem' }}>
                        {getValues('name')}
                      </Typography>
                      <IconButton color="primary" size='small' onClick={() => setIsNameInput(true)}>
                        <EditIcon fontSize='14' />
                      </IconButton>
                    </Stack>
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Description:</Typography>{' '}
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography sx={{ fontSize: '0.9rem' }}>{getValues('description')}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Source:</Typography>{' '}
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography sx={{ fontSize: '0.9rem' }}>{getValues('source')}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Type:</Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography sx={{ fontSize: '0.9rem' }}>{getValues('actionType')}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Agent:</Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography sx={{ fontSize: '0.9rem' }}>{agentName}</Typography>
                </Grid>
                {getValues('createUrlFiles') && (
                  <>
                    <Grid item xs={12} md={3}>
                      <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Proxy:</Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Typography sx={{ fontSize: '0.9rem' }}>
                        {showProxy?.length &&
                          showProxy.map((p, i) => (
                            <span key={i}>
                              {p}
                              {', '}
                            </span>
                          ))}
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={3}>
                  <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Paths included:</Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography sx={{ fontSize: '0.9rem' }}>
                    {getIncludedPaths(
                      getValues('fileSystemType') === 'sharePoint'
                        ? getValues('sharePointPathsList')
                        : getValues('remotePathsList')
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Paths excluded:</Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography sx={{ fontSize: '0.9rem' }}>
                    {getValues('remoteExPathsList') &&
                      getValues('remoteExPathsList').map((path, i) => (
                        <span key={i}>
                          {path}
                          {', '}
                        </span>
                      ))}
                  </Typography>
                </Grid>

                {(getValues('actionType') == 'STUB' ||
                  getValues('actionType') == 'WITHOUTSTUB' ||
                  getValues('actionType') == 'ARCHIVE' ||
                  getValues('actionType') == 'INGESTION' ||
                  getValues('actionType') == 'ACTIVEARCHIVE') && (
                    <>
                      <Grid item xs={12} md={3}>
                        <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Data Target:</Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <Typography sx={{ fontSize: '0.9rem' }}>{getInfo()}</Typography>
                      </Grid>
                    </>
                  )}
                {fileType === 2 || (fileType === 3 && showStorage) ? (
                  <>
                    <Grid item xs={12} md={3}>
                      <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Storage Class :</Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Typography sx={{ fontSize: '0.9rem' }}>
                        {getStorage(fileType === 2 ? selectedStorage : fileType === 3 ? selectedStorageAzure : null)}
                      </Typography>
                    </Grid>
                  </>
                ) : null}
                {fileType == 2 && showRetention ? (
                  <>
                    <Grid item xs={12} md={3}>
                      <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Retention Mode / Date:</Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Typography sx={{ fontSize: '0.9rem' }}>
                        {retentionLabel(retentionData.retentionMode)} / {retentionData.retentionUntilDate}
                      </Typography>
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={12} md={3}>
                  <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Policy:</Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography sx={{ fontSize: '0.9rem' }}>{policyName}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Schedule:</Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography sx={{ fontSize: '0.9rem' }}>{getValues('scheduled') ? 'Run Now' : 'Schedule'}</Typography>
                </Grid>

                {getValues('scheduled') == getValues('Schedule') && (
                  <>
                    <Grid item xs={12} md={3}>
                      <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>Schedule Time:</Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      {getValues('executionIntervalUnit') === 'Monthly' ? (
                        <Typography sx={{ fontSize: '0.9rem' }}>{getMonthlyValue()}</Typography>
                      ) : (
                        <Typography sx={{ fontSize: '0.9rem' }}>
                          {moment(getValues('execTimeStartDate')).format('MM/DD/YYYY hh:mm A')}{' '}
                          {getValues('executionIntervalUnit')}
                        </Typography>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
              <Stack direction="row" gap={4}>
                <Button variant="contained" sx={{ color: '#ffff', ml: '10px' }} onClick={submit}>
                  Save
                </Button>
                <Button variant="contained" sx={{ color: '#ffff', ml: '10px' }} onClick={close}>
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Card>
        </Page>
      </Modal>
    </>
  );
};

export default JobModal;

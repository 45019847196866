import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import CloseIcon from '@mui/icons-material/Close';
import { Card, Container, Dialog, IconButton, LinearProgress, Modal, Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { dispatch } from 'src/redux/store';
import { setFileView } from '../ediscoveryActions';
import MsgReader from '@kenjiuno/msgreader';
import EmailViewer from './EmailViewer';
import DcmViewer from './DcmViewer';

const DocumentViewModal = ({ openModal, setOpenModal, close, ext, docName }) => {
  const { themeStretch } = useSettings();
  const fileView = useSelector((state) => state.ediscoveryReducer.fileView);
  const documentViewLoading = useSelector((state) => state.ediscoveryReducer.documentViewLoading);
  const [doc, setDoc] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  useEffect(() => {
    if (fileView) {
      setPdfFile(fileView)
    }
  }, [ext, fileView])

  // useEffect(() => {
  //   if (!isEmpty(fileView)) {
  //           let link = fileView && fileView.fileLink
  //           let linkLength = link && link.length
  //           let link2 = link.slice(5,linkLength)
  //           let fileExt = fileView && (fileView.fileName).slice(-3)
  //     setDoc([
  //       {
  //         fileType:  fileExt == 'zip' ? 'ext':'pdf',
  //         fileName: fileView.fileName,
  //         uri: `${BASE_URL}${link2}`,
  //       },
  //     ]);
  //   }
  // }, [fileView]);
  const renderJson = (jsonData) => {
    let parsedData = jsonData;

    try {
      if (typeof jsonData === 'string') {
        parsedData = JSON.parse(jsonData);  // Parse the string to remove escape sequences
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
    return (

      <div className="json-container">
        <SyntaxHighlighter language="json" style={docco}>
          {JSON.stringify(parsedData, null, 2)} {/* Stringify JSON with 2 spaces for indentation */}
        </SyntaxHighlighter>
      </div>
    );
  };
  const renderText = (textData) => {
    return (
      <div className="text-container">
        <pre>{textData}</pre>
      </div>
    );
  };
  const renderViewer = () => {
    if (ext === 'json') {
      return renderJson(pdfFile)
    } else if (ext === 'txt' || ext === 'log') {
      return renderText(pdfFile)
    } else if (ext === 'msg') {
      return <EmailViewer emailData={pdfFile} />
    } else if (ext === 'dcm') {
      return <DcmViewer dcmlData={pdfFile} />
    }else {
      return <DocViewer
        documents={[{ uri: pdfFile, fileName: docName }]} // Passing Blob URL to DocViewer
        pluginRenderers={DocViewerRenderers}
        style={{ width: '50%', height: '90vh' }}
        scale={0.7}
        zoomto='page-fit'
      // zoomto="page-fit"
      />
    }
  }
  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setPdfFile(() => null);
          dispatch(setFileView(null))
          close();
        }}
        style={{ overflow: 'auto' }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '100%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ p: '32px', minWidth: '80%', position: 'relative', display: 'flex', justifyContent: 'center' }}>
              <IconButton
                onClick={() => {
                  setPdfFile(() => null); // Reset PDF file on close
                  dispatch(setFileView(null))
                  close(); // Close the modal
                }}
                sx={{
                  position: 'absolute',
                  top: 6,  // Adjust top position
                  right: 6,  // Adjust right position
                  zIndex: 9,  // Ensure the button is above content
                  color: 'gray', // Color of the icon'
                }}
              >
                <CloseIcon />
              </IconButton>
              {pdfFile ? (
                renderViewer(ext)
              ) : (
                <Stack direction='column'>
                  <LinearProgress />
                  <p>Loading... <strong>{docName || ''}</strong> - larger documents may take longer to load.</p>
                </Stack>
              )}
            </Card>
          </Container>
        </Page>
      </Modal>
      {/* <Modal
        open={openModal}
        onClose={() => {
          setDoc([]);
          close();
        }}
        style={{ overflow: 'auto' }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
      {/* <Page sx={{ width: '100%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Card sx={{ p: '20px', minWidth: '80%' }}>
              {doc.length ? (
                <DocViewer
                  documents={doc}
                  initialActiveDocument={doc[0]}
                  pluginRenderers={DocViewerRenderers}
                  style={{ width: '100%', height: 600 }}
                  scale={5.0}
                  zoomto="fullpage"
                />
              ) : (
                <Stack alignItems="center" justifyContent="center">
                  <CircularProgress />
                </Stack>
              )}
              <Stack direction="row" gap={4} sx={{ mt: '20px' }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setDoc([]);
                    close();
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Card>
          </Container>
        </Page> */}
      {/* </Modal> */}
    </>
  );
};

export default DocumentViewModal;

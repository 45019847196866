export const getEpochtime = (date) => {
    return date.getTime();
  }

  export const truncateString = (str, maxLength) => {
    return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
  };

  export const  toLowerCaseAlphabets = (input) => {
    let result = '';
  
    for (let i = 0; i < input.length; i++) {
      let char = input[i];
      if (char >= 'A' && char <= 'Z') {
        result += String.fromCharCode(char.charCodeAt(0) + 32);
      } else {
        result += char;
      }
    }
  
    return result;
  }

  export const getMimeType = (fileExtension) => {
    let mimeType;
  
    switch (fileExtension.toLowerCase()) {
      case "json":
        mimeType = "application/json";
        break;
      case "xml":
        mimeType = "application/xml";
        break;
      case "pdf":
      case "doc":
      case "docx":
      case "ppt":
      case "pptx":
      case "xlsx":
      case "xls":
      case "csv":
      case "CSV":
        mimeType = "application/pdf"; // Adjust if needed for Word, Excel, etc.
        break;
      case "png":
      case "tiff":
        mimeType = "image/png";
        break;
      case "jpg":
      case "jpeg":
        mimeType = "image/jpeg";
        break;
      case "gif":
        mimeType = "image/gif";
        break;
      case "svg":
        mimeType = "image/svg+xml";
        break;
      case "log":
      case "txt":
        mimeType = "text/plain";
        break;
      default:
        mimeType = "application/octet-stream"; // Default MIME type for unknown extensions
        break;
    }
  
    return mimeType;
  };
  
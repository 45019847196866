import axios from 'axios';
import { BASE_URL } from 'src/config';
import axiosInstance from './axios';
import { ERROR_ALERT } from 'src/redux/reducers/alertReducer';

export const getHeaders=()=> {
  const token = localStorage.getItem('accessToken');
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return headers
}

export const GetFetch = ({ url, params, responseType, accept, acceptes }) => {
  let headers = getHeaders();
  if(acceptes){
    headers = {...headers, 'Accept': 'application/pdf'}
  }
  const config = {
    params,
    headers,
  }
  if(responseType){
    config.responseType = responseType
  }
  return axiosInstance.get(`${BASE_URL}${url}`, config);
};
export const GetDetails = ({ url, responseType}) => {
  const headers = getHeaders();
  const config = {
    headers,
  }
  if(responseType){
    config.responseType = responseType
  }
  return axiosInstance.get(`${BASE_URL}${url}`, config);
};

export const PostDetails = ({ url, body, params }) => {
  const headers = getHeaders();
  return axiosInstance.post(`${BASE_URL}${url}`, body,  {
    params,
    headers,
  });
};

export const postInfo = async ({ url, body, method, params, responseType }, dispatch) => {
  let response = {};
  const headers = getHeaders();

  let config = {
    method: method || 'POST',
    url: `${BASE_URL}${url}`,
    data: body,
    headers
  }
  if(params){
    config.params = params;
  }
  if(responseType){
    config.responseType = responseType
  }

  await axios(config).then(async(res) => {
    response = {
      data: res.data,
      status: res.status
    }
  })
  .catch((error) => {
    if (error.response) {
      const {data, status} = error.response;
      if(dispatch){
        if(status === 500){
          dispatch({
            type: ERROR_ALERT,
            payload: data.error || 'Something went wrong',
          });
        } else if(data.validationErrors && data.validationErrors.length > 0) {
          dispatch({
            type: ERROR_ALERT,
            payload: data.validationErrors[0].message || 'Something went wrong',
          });
        }else {
          dispatch({
            type: ERROR_ALERT,
            payload: data.message || 'Something went wrong',
          });
        }
       
      }
      response = {
        data: data,
        status: status
      }
    }
  });
  return response;
};

export const putDetails = ({ url, body }) => {
  const headers = getHeaders();
  return axiosInstance.put(`${BASE_URL}${url}`, body, {
    headers,
  });
};

export const deleteInfo = async ({ url }) => {
  const headers = getHeaders();
  // return axiosInstance.delete(`${BASE_URL}${url}`,{
  //   headers,
  // });
  let response;
  await axios({ method: "DELETE",url :`${BASE_URL}${url}`,headers}).then(async(res) => {
    response = {
      data: res.data,
      status: res.status
    }
  }).catch((error) => {
    response = error.response
  })
  return response;
};

